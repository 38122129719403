var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',{staticClass:"card-header fixed-card-title2"},[_vm._v("\n        "+_vm._s(_vm.$t('addMedicalTeam'))+"\n        "),_c('v-spacer'),_vm._v(" "),_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.closeDrawer}},[_vm._v("mdi-window-close")])],1),_vm._v(" "),_c('v-card-text',{staticClass:"mt-3",attrs:{"color":"primary"}},[_c('v-form',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"my-1",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search-Patient'),"single-line":"","hide-details":""},model:{value:(_vm.searchPatient),callback:function ($$v) {_vm.searchPatient=$$v},expression:"searchPatient"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"500px"},attrs:{"dense":"","fixed-header":"","items":_vm.filteredPatients,"item-key":"item.id","no-data-text":_vm.$t('no-data-available'),"loading-text":_vm.$t('loading'),"hide-default-footer":"","height":_vm.testHeight,"search":_vm.searchPatient,"headers":_vm.patientHeadr,"items-per-page":-1,"footer-props":{
									itemsPerPageText: this.$t('row'),
									itemsPerPageAllText: this.$t('all'),
								}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{class:_vm.selectedRows.indexOf(item)>-1?'primary':'',style:(_vm.selectedRows.indexOf(item)>-1?'color: white':''),on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),_vm._v(" "),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.email))])])]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"my-1",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search-medical-team'),"single-line":"","hide-details":""},model:{value:(_vm.searchMedicalTeam),callback:function ($$v) {_vm.searchMedicalTeam=$$v},expression:"searchMedicalTeam"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"dense":"","fixed-header":"","no-data-text":_vm.$t('no-data-available'),"loading-text":_vm.$t('loading'),"headers":_vm.staffHeadr,"items":_vm.staffData,"hide-default-footer":"","item-key":"id","search":_vm.searchMedicalTeam,"height":_vm.testHeight,"items-per-page":-1,"footer-props":{
									itemsPerPageText: this.$t('row'),
									itemsPerPageAllText: this.$t('all'),
								}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticStyle:{"text-align":"left"}},[_c('img',{staticClass:"mx-1",attrs:{"src":item.role === 'Doctor'
												? _vm.doctor_black
												: _vm.nurse_black,"color":"primary","height":"18px","alt":""}}),_vm._v("\n                      "+_vm._s(item.fullName)+"\n                    ")]),_vm._v(" "),_c('td',[_c('v-checkbox',{staticClass:"pa-0 ma-0 d-flex justify-end",attrs:{"hide-details":"","disabled":item.id === _vm.primaryDoctorSelected ? true : false,"color":"primary","value":item.id},model:{value:(_vm.staffSelected),callback:function ($$v) {_vm.staffSelected=$$v},expression:"staffSelected"}})],1),_vm._v(" "),_c('td',[_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},model:{value:(_vm.primaryDoctorSelected),callback:function ($$v) {_vm.primaryDoctorSelected=$$v},expression:"primaryDoctorSelected"}},[(item.roleId === 3)?_c('v-radio',{key:item.id,staticClass:"d-flex justify-center",attrs:{"value":item.id,"color":"primary"}}):_vm._e()],1)],1)])]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"fixed-card-actions"},[_c('v-row',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"cancel-button-style capitalize-text",attrs:{"elevation":"0"},on:{"click":_vm.closeDrawer}},[_vm._v("\n              "+_vm._s(_vm.$t('cancel'))+"\n            ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"save-button-style mx-1",attrs:{"loading":_vm.loading,"disabled":_vm.loading || _vm.checkValidity},on:{"click":function($event){_vm.loader = 'loading'}}},[_c('span',[_vm._v(_vm._s(_vm.$t("save")))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }