<template>
  <v-card
      class="mx-auto"
      elevation="0"
  >
    <v-card-title class="card-header fixed-card-title">
      <v-row dense>
        <v-col class="align-center d-flex" cols="10">
          <v-toolbar-title>{{ $t("add-staff") }}</v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-16">
      <v-form
          ref="form"
          lazy-validation
      >
        <!-- Data -->
        <v-container>
          <v-row class="pt-2">
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.firstName"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('first-name')"
                  :key="'textField' + componentKey"
                  required>

              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.lastName"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('last-name')"
                  :key="'textField2' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-select
                  class="width-text-field2"
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.roleId"
                  :items="filteredRoles"
                  item-value="id"
                  item-text="roleNameForDisplay"
                  :key="'textField5' + componentKey"
                  :label="$t('role')"
                  outlined
                  :menu-props="{ offsetY: true }"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-select
                  class="width-text-field2"
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.organizationId"
                  :items="filterOrganizations"
                  item-value="id"
                  item-text="institutionName"
                  :label="$t('medicalInstitution')"
                  :key="'textField3' + componentKey"
                  @change="setSelectedHospitalOrOrganization"
                  outlined
                  :menu-props="{ offsetY: true }"
              >

              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="hospitalAdminToAdd.roleId === 6">
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-select
                  class="width-text-field2"
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.hospitalId"
                  :items="filteredHospitals"
                  item-value="id"
                  item-text="hospitalName"
                  :label="$t('hospital')"
                  :key="'textField4' + componentKey"
                  @change="setSelectedHospitalOrOrganization"
                  outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <div class="pt-4">
                <h5 class="heading text-left">{{this.$t('address')}}</h5>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.address"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('address')"
                  :key="'textField6' + componentKey"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.city"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('city')"
                  :key="'textField7' + componentKey"
                  required>
              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.state"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('state')"
                  :key="'textField8' + componentKey"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.zip"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('zip')"
                  :key="'textField9' + componentKey"
                  required>
              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.country"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('country')"
                  :key="'textField10' + componentKey"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!addressIsValid">
            <v-col class="pa-1">
              <v-combobox
                  :label="$t('time-zone')"
                  dense
                  hide-details="auto"
                  outlined
                  :rules="[v => !!v || $t('timeZoneSelect')]"
                  v-model="timezone"
                  item-text="timeZoneId"
                  item-value="timeZoneId"
                  :items="getTimeZone"
                  :key="componentKey"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <div class="pt-4">
                <h5 class="heading text-left">{{this.$t('contact')}}</h5>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.phone"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('contact-phone')"
                  @input="formatPhoneNumber"
                  :key="'textField11' + componentKey"
                  required>
              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalAdminToAdd.email"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('contact-email')"
                  :key="'textField12' + componentKey"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mx-0 px-0 mr-2">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text">
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="isSaveButtonDisabled()"
              class="save-button-style"
              @click="saveHospitalAdmin">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import HospitalAdminEditDto from '../../data/models/HospitalAdminEditDto';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import { AsYouType } from 'libphonenumber-js';

export default {
  name: 'HopsitalAdminAddDrawer',
  data: () => ({
    componentKey: 0,
    hospitalAdminToAdd: new HospitalAdminEditDto(),
    selectedHospitalOrOrganization: null,
    addressIsValid: true,
    institutions: null,
    timezone: null,
  }),
  async mounted () {
    await this.getTimeZones();
    await this.getAllMedicalInstitutions();
    await this.getAllHospitals();
  },
  computed: {
    ...mapState({
      allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      hospitals: (state) => state.hospitals.hospitals,
      allRoles: (state) => state.roles.roles,
      authenticated: state => state.authentication.userData,
    }),
    ...mapGetters({
      getTimeZone: 'verifiers/getTimeZone',
      getMedicalInstitutions: 'medicalInstitutions/getMedicalInstitutions',
      roleName: 'authentication/getRole',
    }),
    filterOrganizations () {
     if (this.roleName === 'SYSTEM_ADMIN') {
       const institutions = this.allMedicalInstitutions.slice();
       institutions.shift();
       return institutions;
     }
     return this.allMedicalInstitutions;
    },
    filteredHospitals () {
      let calculateFileteredHospital = this.hospitals;
      if (this.hospitalAdminToAdd.organizationId !== null && this.hospitalAdminToAdd.organizationId !== 0) {
        calculateFileteredHospital = calculateFileteredHospital.filter(h => h.medicalInstitutionId === this.hospitalAdminToAdd.organizationId);
      } else {
        calculateFileteredHospital = this.hospitals;
      }
      return calculateFileteredHospital;
    },
    filteredRoles () {
      let filteredRolesReturn = [];

      if (this.roleName === 'SYSTEM_ADMIN') {
        filteredRolesReturn = this.allRoles.filter(r =>
            r.roleName === 'HOSPITAL_ADMIN' ||
            r.roleName === 'ORGANIZATION_ADMIN' ||
            r.id === 9,
        );
      } else if (this.roleName === 'ORGANIZATION_ADMIN') {
        filteredRolesReturn = this.allRoles.filter(r =>
            r.roleName === 'HOSPITAL_ADMIN' ||
            r.roleName === 'ORGANIZATION_ADMIN',
        );
      }

      return filteredRolesReturn;
    },
  },
  methods: {
    async getPartners () {
      const organizationId = this.roleName === 'SYSTEM_ADMIN' ? -1 : this.authenticated.organizationId;
      const body = {
        organizationId: organizationId,
      };

      await this.$store.dispatch('users/getPartners', body);
    },
    isSaveButtonDisabled () {
      return (
          !this.hospitalAdminToAdd.firstName ||
          !this.hospitalAdminToAdd.lastName ||
          !this.hospitalAdminToAdd.roleId ||
          !this.hospitalAdminToAdd.address ||
          !this.hospitalAdminToAdd.city ||
          !this.hospitalAdminToAdd.state ||
          !this.hospitalAdminToAdd.zip ||
          !this.hospitalAdminToAdd.country ||
          !this.hospitalAdminToAdd.phone ||
          !this.hospitalAdminToAdd.email ||
          !this.hospitalAdminToAdd.organizationId
      );
    },
    getTimeZones () {
      this.$store.dispatch('verifiers/getTimeZones').then(() => {
      });
    },
    setSelectedHospitalOrOrganization (val) {
      if (this.hospitalAdminToAdd.roleId === 6) {
        this.selectedHospitalOrOrganization = this.hospitals.find(hospital => hospital.id === val);
        // eslint-disable-next-line no-empty
        if (this.selectedHospitalOrOrganization !== undefined && this.selectedHospitalOrOrganization !== null) {
        } else {
          return;
        }
      } else {
        this.selectedHospitalOrOrganization = this.allMedicalInstitutions.find(org => org.id === val);
      }

        this.hospitalAdminToAdd.address = this.selectedHospitalOrOrganization.address;
        this.hospitalAdminToAdd.city = this.selectedHospitalOrOrganization.city;
        this.hospitalAdminToAdd.state = this.selectedHospitalOrOrganization.state;
        this.hospitalAdminToAdd.zip = this.selectedHospitalOrOrganization.zip;
        this.hospitalAdminToAdd.country = this.selectedHospitalOrOrganization.country;
        if (this.selectedHospitalOrOrganization?.addressLatitude !== 0.01 && this.selectedHospitalOrOrganization.addressLongitude !== 0.01 && this.selectedHospitalOrOrganization.addressLongitude !== null && this.selectedHospitalOrOrganization?.addressLatitude !== null) {
          // TODO there is good address call  edpoint for geting the correct time zone with given lan lng

          this.addressIsValid = true;

          const latLng = {
            lat: this.selectedHospitalOrOrganization?.addressLatitude,
            lng: this.selectedHospitalOrOrganization.addressLongitude,
          };

          this.$store.dispatch(
              'googleAPI/getTimeZone',
              latLng,
          ).then(response => {
            this.timezone = response.zoneName;
          });
        } else {
          // TODO There is bad address show time zoene lookup
          this.addressIsValid = false;
          this.selectedHospitalOrOrganization.addressLatitude = 0.01;
          this.selectedHospitalOrOrganization.addressLongitude = 0.01;
        }
    },
    formatPhoneNumber (val) {
      this.hospitalAdminToAdd.phone = new AsYouType().input(val);
    },
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.hospitalAdminToAdd.firstName = '';
      this.hospitalAdminToAdd.lastName = '';
      this.hospitalAdminToAdd.organizationId = '';
      this.hospitalAdminToAdd.hospitalId = '';
      this.hospitalAdminToAdd.roleId = '';
      this.hospitalAdminToAdd.address = '';
      this.hospitalAdminToAdd.city = '';
      this.hospitalAdminToAdd.state = '';
      this.hospitalAdminToAdd.zip = '';
      this.hospitalAdminToAdd.country = '';
      this.hospitalAdminToAdd.phone = '+1';
      this.hospitalAdminToAdd.email = '';

      // Change the key to trigger component reset
      this.componentKey += 1;
    },
    async getOrganizationAmdinUsers () {
        await this.$store.dispatch('users/getOrganizationAdmins').then(() => {
            this.closeDrawer();
        });
    },
    async getHospitalAmdinsUsers () {
			// await this.$store.dispatch('hospitals/getHospitalAdmins').then(() => {
      await this.$store.dispatch('users/getHospitalAdmins').then(() => {
				this.closeDrawer();
			});
		},
    async getCaregivers () {
      await this.$store.dispatch('users/getCaregivers').then(() => {
          this.closeDrawer();
      });
    },
    async getAllMedicalInstitutions () {
			// we call this API endpoint only if the store values are null
			if (this.allMedicalInstitutions == null) {
				await this.$store.dispatch('medicalInstitutions/getMedicalInstitutions').then(() => {
					// this.loading = false;
				});
			}
		},
    async getAllHospitals () {
			// we call this API endpoint only if the store values are null
			if (this.hospitals == null) {
				await this.$store.dispatch('hospitals/getHospitals').then(() => {
					// this.loading = false;
				});
			}
		},
    async saveHospitalAdmin () {
      const waitAddressValidation = await this.checkAndFixLocationDetails();
      if (!waitAddressValidation) {
          // this.validationErrors.push('address');
          // go on with the given addres data
      }

      this.hospitalAdminToAdd.phone = new AsYouType().input(this.hospitalAdminToAdd.phone); // TODO: this we need to do ONLY on presentation, NOT when saving on DB

      if (this.$refs.form.validate()) {
        const body = {
          firstName: this.hospitalAdminToAdd.firstName,
          lastName: this.hospitalAdminToAdd.lastName,
          phone: this.hospitalAdminToAdd.phone,
          email: this.hospitalAdminToAdd.email,
          address: this.hospitalAdminToAdd.address,
          zip: this.hospitalAdminToAdd.zip,
          city: this.hospitalAdminToAdd.city,
          state: this.hospitalAdminToAdd.state,
          country: this.hospitalAdminToAdd.country,
          addressLatitude: this.selectedHospitalOrOrganization?.addressLatitude,
          addressLongitude: this.selectedHospitalOrOrganization?.addressLongitude,
          username: this.hospitalAdminToAdd.email,
          status: 1, // this.hospitalAdminToAdd.status,
          // gender: this.hospitalAdminToAdd.gender,
          // birthdate: this.hospitalAdminToAdd.birthdate,
          roleId: this.hospitalAdminToAdd.roleId,
          // userTimezone: this.hospitalAdminToAdd.userTimezone,
          // userSettingsJson: this.hospitalAdminToAdd.userSettingsJson,
          // language: this.hospitalAdminToAdd.language,
          // hospitalId: this.hospitalAdminToAdd.hospitalId,
          organizationId: this.hospitalAdminToAdd.organizationId,
          userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,
        };

        if (this.hospitalAdminToAdd.roleId === 6) {
          body.hospitalId = this.hospitalAdminToAdd.hospitalId;
        }
        await this.$store.dispatch('users/createUser', body)
          .then((res) => {
            if (res) {
              showSuccessAlert(this.$t('success-operation'));
              if (this.hospitalAdminToAdd.roleId === 6) {
                this.getHospitalAmdinsUsers(); // reload the list of hospital admins
              } else if (this.hospitalAdminToAdd.roleId === 8) {
                this.getOrganizationAmdinUsers(); // reload the list of organization admins
              } else if (this.hospitalAdminToAdd.roleId === 7) {
                this.getCaregivers(); // reload the list of caregivers
              } else if (this.hospitalAdminToAdd.roleId === 9) {
                this.closeDrawer();
                this.getPartners(); // reload the list of caregivers
              }
              this.resetComponent();
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
    closeDrawer () {
      this.hospitalAdminToAdd = new HospitalAdminEditDto();
      this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
    },
    async checkAndFixLocationDetails () {
      try {
        if (this.hospitalAdminToAdd.address !== '' && this.hospitalAdminToAdd.city !== '' && this.hospitalAdminToAdd.zip !== '' && this.hospitalAdminToAdd.state !== '' && this.hospitalAdminToAdd.country !== '') {
          var addressForValidation;
          addressForValidation = this.hospitalAdminToAdd.address + ', ' + this.hospitalAdminToAdd.city + ', ' + this.hospitalAdminToAdd.state + ' ' + this.hospitalAdminToAdd.zip + ', ' + this.hospitalAdminToAdd.country;
          const geoLocation = await this.$store.dispatch(
            'googleAPI/getGeoLocation',
            addressForValidation,
          );
          if (!geoLocation) {
            this.hospitalAdminToAdd.addressLatitude = 0.01;
            this.hospitalAdminToAdd.addressLongitude = 0.01;
            return false;
          }
          if (geoLocation.address === undefined || geoLocation.address == null) {
            this.hospitalAdminToAdd.addressLatitude = 0.01;
            this.hospitalAdminToAdd.addressLongitude = 0.01;
            return false;
          }
          const addressComponents = geoLocation.address;

          // this.hospitalAdminToAdd.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
          // this.hospitalAdminToAdd.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
          this.selectedHospitalOrOrganization.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
          this.selectedHospitalOrOrganization.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;

          this.hospitalAdminToAdd.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.hospitalAdminToAdd.address : geoLocation.display_name;
          this.hospitalAdminToAdd.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
          this.hospitalAdminToAdd.state = addressComponents.state || 'N/A';
          this.hospitalAdminToAdd.country = addressComponents.country || 'N/A';
          this.hospitalAdminToAdd.zip = addressComponents.postcode || 'N/A';
          this.addressIsValid = true;
          this.timezone = addressComponents.timezone || 'N/A';
          return true;
        } else {
          this.hospitalAdminToAdd.addressLatitude = 0.01;
          this.hospitalAdminToAdd.addressLongitude = 0.01;
          return false;
        }
      } catch (error) {
        this.hospitalAdminToAdd.addressLatitude = 0.01;
        this.hospitalAdminToAdd.addressLongitude = 0.01;
        return false;
      }
    },
  },
};
</script>
